import './App.scss';
import React from 'react';
import Web3 from 'web3'; 
import { chainMap, enforceChain } from './tools/ChainTools.js'
import { FarmgodCore, contractConfigs } from './tools/farmgod-core.js'


const f = new Intl.NumberFormat('en-US')


function App() {
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Connecting to Metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([])
  const [mmBtnText, setMMBtnText] = React.useState("Connect")
 
  // attached to the accountsChanged event listener
  // triggered once manually via connectMM
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  // triggered once manually via main hook
  // calls letItRip if the proper chain is selected
  function handleChainChange(chainId) {
    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId));
     enforceChain("Fantom", letItRip)
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true
  const connectMM = () => {
    if (!connected) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then((newAccounts) => {
          handleNewAccounts(newAccounts)
          setConnected(true)})
    }
  }

  
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
        window.ethereum.on('chainChanged', handleChainChange);
      };
    }
  }, [connected]);



  
  // --------- -------------------------------------------------------------------------------
  // MAIN HOOK -------------------------------------------------------------------------------
  // --------- -------------------------------------------------------------------------------

  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])



  // --------- -------------------------------------------------------------------------------

  // -- end of connecting to metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  

  const [isDisabled, setDisabled] = React.useState(false);

  // this is a reference to the input field
  const theInputRef = React.createRef();

  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);
  const [pendingText, setPendingText] = React.useState("Pending Transaction")

  // initialize web3 
  const web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.ftm.tools'));

  
  const letItRip = () => {
    console.log("let it rip")
  }

const getTransactionReceipt = (hash, callback= ()=>{}) => {
  console.log(hash)
  window.ethereum.request({
    method: "eth_getTransactionReceipt",
    params: [hash]
  }).then((res)=>{
    if (res == null) {
      getTransactionReceipt(hash, callback)
    }
    callback(res)
  }).catch((err)=>{
    console.log(err)
  })
}


const estimateGas = (contract, data, saveError, callback = ()=>{}) => {
   window.ethereum.request(
        {
          method: "eth_estimateGas",
          params: [
            {
              from: window.ethereum.selectedAddress,
              to: contract,     
              data: data,
            },
          ],
        }
      ).then((gasAmount)=>{
        callback()
      }).catch((err)=>{

        saveError(err.data.message)
        if (err.data.message.includes("shrine not available")) {
          alert("Shrine is currently occupied. Please switch Shrines or wait another minute or two.")
        } else {
          alert(err.data.message)
        }
        
      })
}

const sendRequest = (contract, data, callback = ()=>{}) => {
  setIsPending(true)

  window.ethereum.request({
    method: "eth_sendTransaction",
    params: [
      {
        from: window.ethereum.selectedAddress,
        to: contract,
        value: 0,
        data: data,
      },
    ],}).then((hash)=>{
      getTransactionReceipt(hash,(receipt)=>{
        if (receipt != null) {
          setIsPending(false)
          callback()
        }
      })
    }).catch((err)=>{
      setIsPending(false)
    })
}


const bal = (token, callback = ()=>{}) => {
  token.methods.balanceOf(window.ethereum.selectedAddress)
    .call({from: window.ethereum.selectedAddress})
    .then((balance) => {
      callback(balance)
    })
}


  // ----------------------------------------------------------------------------------------------------
const migration = "0x346EE9891071d70Ce6afAcE7882C4aF54d411d1D"
const outputs = ["DELI","LAND","Resources","Refined","Other"]
const inputs = [
  ["gDELI","mgDELI","ENERg","pENERg"],
  ["ACRE","UGU"],
  ["SUGARCANE","WHEAT","gSEED","SALT","WATER","YOMOPU"],
  ["PUJU","MOMI","SUGAR","BROWNSUGAR","FLOUR","WHOLEFLOUR","gcOIL","gpOIL"],
  ["ANIMAL","LIGGIES"]
  ]

const rates = [
  [1,1/1000,1,1],
  [1,20000]
]

const [selectedOutput, setSelectedOutput] = React.useState(9)
const selectOutput = (i) => {
  return () => {
    setSelectedOutput(i)
  }
}

const [selectedInput, setSelectedInput] = React.useState(9)
const selectInput = (i) => {
  return () => {
    //alert("selecting input: " + i)
    setSelectedInput(i)

  }
}

const tokenName = () => {
  return inputs[selectedOutput][selectedInput].toLowerCase()
}
const theToken = () => {
  return new web3.eth.Contract(contractConfigs["generic"]["abi"], FarmgodCore[tokenName()]["address"])
} 

const transfer = (t,balance) => {
  let data = t.methods.transfer(migration,balance).encodeABI()
  sendRequest(FarmgodCore[tokenName()]["address"], data, (receipt)=>{
    alert("Thanks for migrating. First distribution will be at launch of Public Mainnet.")
  })
}

const migrate = (callback = ()=>{}) => {
  let t = theToken()
  bal(t,(balance)=>{

    let viewBal = web3.utils.fromWei(balance,"shannon")
    if (selectedOutput > 0) {
      viewBal = web3.utils.fromWei(balance,"ether")
    }
    if ( viewBal > 0) {
      alert("Migrating " +viewBal+ " " + tokenName())
      transfer(t,balance)
    } else {
      alert("You either have nothing to migrate or the rpc balance request failed.")
    }
  })
} 

React.useEffect(()=>{
  if (selectedOutput < outputs.length) {
    if (selectedInput < inputs[selectedOutput].length) {
      migrate()
    }
  }

},[selectedInput])

  return (
    <div className="App">
      <button 
        disabled={isDisabled} 
        onClick={connectMM} 
        className="mmbtn">
        {mmBtnText}
      </button>

      <div>
        <p>Select your output on Sonic</p>
        <p>{outputs.map((output,i)=><button onClick={selectOutput(i)} key={"output"+i}>{output}</button>)}</p>
        <p>Select your input on Fantom</p>
        <div className={"inputs inputs--" + selectedOutput}>
          {
            inputs.map(
              (inputGroup,outputIndex)=>(
                <div className={"inputGroup inputGroup--"+outputIndex}>

                  {
                    inputGroup.map((input,inputIndex)=>(

                      <button onClick={selectInput(inputIndex)} className={"input input--" +inputIndex}>{input}</button>
                      ))
                  }
                </div>
                )
              )
          }
        </div>
      </div>

      <div className={"modal modal--pending screen-showing--" + isPending}>
        <h2>Pending Transaction</h2>
        <div className="modal__inside">
          <p>{pendingText}</p>
        </div>
      </div>

      <div className={"connected-address screen-showing--" + connected}>Connected as: {(accounts.length > 0) ? accounts[0] : ""}</div>

    </div>
  );
}

export default App;
